import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import { request } from 'util/client'
import { LoadingButton } from '@mui/lab'
import { ArrowForward } from '@mui/icons-material'

const Form = ({ status, setStatus }) => {
  const platformList = [
    {
      name: 'Website',
      link: 'https://',
      displayLink: '',
    },
    {
      name: 'LinkedIn',
      link: 'https://linkedin.com/in/',
      displayLink: 'linkedin.com/in/',
    },
    {
      name: 'Linktree',
      link: 'https://linktr.ee/',
      displayLink: 'linktr.ee/',
    },
    {
      name: 'Twitter',
      link: 'https://twitter.com/',
      displayLink: 'twitter.com/',
    },
    {
      name: 'Instagram',
      link: 'https://instagram.com/',
      displayLink: 'instagram.com/',
    },
  ]

  const { cid } = useParams()
  const [platform, setPlatform] = useState(0)
  // const [username, setUsername] = useState('')

  const [createUserId, setCreateUserId] = useState(true)

  console.log(createUserId)

  const handleChange = event => {
    setCreateUserId(event.target.checked)
  }

  const handleSubmit = async values => {
    const urlOrUsername = values.urlOrUsername

    let url
    let { link, displayLink } = platformList[platform]

    console.log(urlOrUsername.indexOf(displayLink))

    if (platform !== 0 && urlOrUsername.indexOf(displayLink) < 0) {
      url = link + urlOrUsername
    } else if (
      urlOrUsername.indexOf('http://') !== 0 ||
      urlOrUsername.indexOf('https://') !== 0
    ) {
      url = `http://${urlOrUsername}`
    } else {
      url = urlOrUsername
    }

    // if (urlOrUsername.indexOf('http://') !== 0 && url.indexOf('https://') !== 0) {
    //   url = `http://${url}`
    // }

    if (status === 'unclaimed' && !!cid) {
      setStatus('pending')
      let user
      try {
        const res = await request({
          url: `/claims`,
          data: { path: cid, url, createUserId },
          method: 'POST',
        })
        console.log(res.user)
        user = res.user
        if (!!user && user.id) {
          try {
            localStorage.setItem('tempUserId', user.id)
          } catch (err) {
            console.log(err)
          }
        }
        setStatus('success')
      } catch (err) {
        setStatus('failed')
      }
    }
  }

  const validationSchema = yup.object({
    urlOrUsername: yup
      .string('Enter a URL or username')
      .transform(value => {
        let url
        let { link, displayLink } = platformList[platform]

        if (platform !== 0 && value.indexOf(displayLink) !== 0) {
          url = link + value
        } else if (
          value.indexOf('http://') !== 0 &&
          value.indexOf('https://') !== 0
        ) {
          url = `http://${value}`
        } else {
          url = value
        }

        console.log(url)
        return url
      })
      .url('Must be a valid URL or username')
      // .matches('^[.:/@a-zA-Z0-9_-]*$', 'Must be a valid URL or username')
      .required('Required'),
    // .notOneOf(
    //   [platformList.map(platformItem => platformItem.link)],
    //   'Looks like you forgot to add a username'
    // ),
  })

  const formik = useFormik({
    initialValues: {
      urlOrUsername: '',
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: handleSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container justifyContent="flex-start" spacing={2}>
        {/* <Grid item xs={12} hidden>
          <TextField
            variant="outlined"
            fullWidth
            label="URL"
            inputProps={{ autoCapitalize: 'none' }}
            autoComplete="off"
            placeholder="https://"
            {...formik.getFieldProps('url')}
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h6" pb={0.5}>
            What type of link are you sharing?
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="platform-label">Link Type</InputLabel>
            <Select
              labelId="platform-label"
              value={platform}
              label="Link Type"
              onChange={event => {
                setPlatform(event.target.value)
                // formik.setFieldValue(
                //   'url',
                //   platformList[event.target.value].link + username
                // )
              }}
            >
              {platformList.map((platformItem, index) => (
                <MenuItem key={platformList[index].name} value={index}>
                  {platformList[index].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" pb={0.5}>
            Enter your{' '}
            {platform === 0
              ? 'link'
              : platformList[platform].name + ' url or username'}
          </Typography>
          <TextField
            variant="outlined"
            fullWidth
            label={platform === 0 ? 'url' : 'url or username'}
            placeholder={platformList[platform].displayLink}
            // value={username}
            autoComplete="off"
            {...formik.getFieldProps('urlOrUsername')}
            // onChange={event => {
            //   setUsername(event.target.value)
            //   formik.setFieldValue(
            //     'url',
            //     platformList[platform].link + event.target.value
            //   )
            // }}
            // InputProps={{
            //   startAdornment: platformList[platform].displayLink,
            // }}
            inputProps={{ autoCapitalize: 'none' }}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              formik.touched.urlOrUsername &&
              Boolean(formik.errors.urlOrUsername)
            }
            helperText={
              formik.touched.urlOrUsername && formik.errors.urlOrUsername
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={createUserId} onChange={handleChange} />
            }
            label={
              <b>
                Save scan history (view anytime by re-scanning your own badge)
              </b>
            }
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="large"
            fullWidth
            loading={formik.isSubmitting === true || status === 'submitted'}
            endIcon={<ArrowForward />}
          >
            <Typography letterSpacing={1} style={{ fontWeight: 900 }}>
              Claim Badge
            </Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
