import React, { useEffect, useState } from 'react'
import { Button, Container, Grid, Link, Typography } from '@mui/material'
import usePageTrack from 'hooks/use-page-track'

import Loading from 'components/Loading'
import { request } from 'util/client'
import { useNavigate } from 'react-router-dom'
import FieldEditLink from './components/FieldEditLink'
import { CallMade, CallReceived, OpenInNew } from '@mui/icons-material'
import { Box } from '@mui/system'

const MyProfile = () => {
  const [status, setStatus] = useState('loading')
  const [user, setUser] = useState({})
  // const [tempUserId, setTempUserId] = useState(null)
  const navigate = useNavigate()

  // useEffect(() => {
  //   const tempUserId = localStorage.getItem('tempUserId')
  //   setTempUserId(tempUserId)
  // }, [])

  // console.log(tempUserId)

  useEffect(() => {
    const tempUserId = localStorage.getItem('tempUserId')

    const getClaimtag = async () => {
      try {
        const res = await request({
          url: `/temp-users/${tempUserId}`,
        })

        setUser(res)
        setStatus('succeeded')
      } catch (err) {
        console.log(err)
        setStatus('failed')
      }
    }

    if (!!tempUserId) {
      if (status === 'loading') {
        getClaimtag()
      }
    }

    return
  }, [status, navigate])

  usePageTrack()

  const setClaimtag = claimtag => {
    setUser({ ...user, claimtag })
  }

  const history = user.scans
    ? [
        ...new Map(
          user.scans.map(scan => scan.claimtag).map(item => [item.id, item])
        ).values(),
      ].filter(claimtag => !!claimtag.url && claimtag.id !== user.claimtag.id)
    : []

  console.log(history)

  if (status === 'failed') {
    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" pt={7} textAlign="center">
              Something Went Wrong{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography>
              Sorry, something went wrong. Please refresh the page and try
              again.
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button size="small" onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Container>
    )
  } else if (status === 'succeeded') {
    return (
      <Container>
        <Grid container justifyContent="center" spacing={2} pt={1}>
          <Grid item xs={11}>
            <Typography variant="h4" pt={7} textAlign="center">
              Your Claimtag
            </Typography>
          </Grid>
          <Grid item xs={11} textAlign="center">
            <Typography variant="h6">Your Link</Typography>
          </Grid>
          {!!user.claimtag && (
            <Grid item xs={11}>
              <FieldEditLink
                url={user.claimtag.url}
                setClaimtag={setClaimtag}
              />
            </Grid>
          )}
          <Grid item xs={11} textAlign="center">
            <Typography variant="h6" pt={2}>
              Your Connections
            </Typography>
          </Grid>
          <Grid item xs={11} container spacing={1}>
            {history.length > 0 ? (
              history.map((claimtag, index) => (
                <Grid item xs={11} key={index}>
                  <Box
                    display="flex"
                    maxWidth="100%"
                    alignItems="center"
                    flexWrap="none"
                  >
                    <Box flexGrow={1} flexBasis={0} minWidth={0}>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{
                          borderRadius: 50,
                          textTransform: 'none',
                        }}
                        href={claimtag.url}
                        target="_blank"
                      >
                        <Box
                          width="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {claimtag.url.replace('https://', '')}
                          </Box>
                          <OpenInNew fontSize="medium" sx={{ pl: 1 }} />
                        </Box>
                      </Button>
                      {/* <Card
                              variant="outlined"
                              elevation={0}
                              sx={{ borderRadius: 50 }}
                            >
                              <CardActionArea>
                                <CardContent>
                                  <Typography textOverflow={'ellipsis'} overflow="hidden">
                                    testtesttesttesttestthisiswhatitlookslikewithalongurl
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card> */}
                    </Box>
                    {user.claimtag.id === claimtag.id ? (
                      <CallReceived color="grey.500" sx={{ pl: 1 }} />
                    ) : (
                      <CallMade color="grey.500" sx={{ pl: 1 }} />
                    )}
                  </Box>
                </Grid>
              ))
            ) : (
              <Typography
                color="text.secondary"
                variant="body2"
                textAlign="center"
              >
                Looks like you haven't made any connections yet. Check back once
                you've scanned a few Claimtags!
              </Typography>
            )}
          </Grid>
          {/* <Grid item xs={11} textAlign="center">
            <TableContainer>
              <Table>
                <TableBody>
                  {!user.history || user.history.length === 0 ? (
                    <TableRow>
                      <TableCell
                      // sx={{
                      //   maxWidth: 0,
                      //   overflow: 'hidden',
                      // }}
                      >
                        Looks like you haven't made any connections yet. Check
                        back once you've scanned a few Claimtags!
                      </TableCell>
                    </TableRow>
                  ) : (
                    user.history.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell
                          sx={{
                            maxWidth: 0,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {item.name ||
                            item.title ||
                            item.titleInternal ||
                            'No Title'}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid> */}
          <Grid item xs={12}>
            <Box height="30px" />
          </Grid>
          <Grid item xs={11}>
            <div
              style={{
                bottom: 0,
                left: 0,
                right: 0,
                top: 'auto',
                textAlign: 'center',
                borderTop: '1px solid #e0e0e0',
                paddingTop: '5px',
                paddingBottom: '10px',
                paddingRight: '5px',
                paddingLeft: '5px',
                position: 'fixed',
                zIndex: 2000,
                backgroundColor: 'white',
                fontSize: '12px',
              }}
            >
              <Typography color="inherit" variant="body2" textAlign="center">
                Create Claimtags for your next event at{' '}
              </Typography>
              <Typography color="inherit" variant="body2" textAlign="center">
                <Link href="https://claimtag.io" target="_blank">
                  <b>www.claimtag.io</b>
                </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  } else {
    return <Loading />
  }
}

export default MyProfile
