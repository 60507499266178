import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  Link,
} from '@mui/material'
import usePageTrack from 'hooks/use-page-track'

import Loading from 'components/Loading'
import logo from 'images/claimtag-logo.svg'
import { request } from 'util/client'
import { useParams } from 'react-router'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Form from './components/Form'
// import Emoji from 'components/Emoji'
import {
  AddLink,
  ArrowForwardIos,
  QrCode,
  PanTool,
  ThumbUp,
} from '@mui/icons-material'
import PaginationDots from 'components/PaginationDots'

const ClaimtagForm = () => {
  const [page, setPage] = useState(0)
  const [status, setStatus] = useState('loading')
  const { cid } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const getClaimtag = async () => {
      try {
        const res = await request({
          url: `/claimtags/${cid}`,
        })

        const claimtag = res.claimtag

        if (!!claimtag && !!claimtag.url) {
          navigate('/' + cid)
        } else if (!!claimtag && !claimtag.url) {
          setStatus('unclaimed')
        } else {
          setStatus('failed')
        }
      } catch (err) {
        console.log(err)
        setStatus('failed')
      }
    }

    if (!!cid) {
      if (status === 'loading') {
        getClaimtag()
      }
    } else {
      setStatus('failed')
    }

    return
  }, [cid, status, navigate])

  usePageTrack()

  const pages = [
    {
      text: (
        <>
          Enter a link you'd like to share (like your LinkedIn, Linktree or
          Instagram)
        </>
      ),
      Icon: <AddLink sx={{ fontSize: 100, transform: 'rotate(-45deg)' }} />,
    },
    {
      text: (
        <>
          The next time someone scans your badge, they'll be taken to your link
        </>
      ),
      Icon: <QrCode sx={{ fontSize: 100 }} />,
    },
    {
      text: (
        <>Scan other attendees badges to connect with them after the event!</>
      ),
      Icon: <PanTool sx={{ fontSize: 100, transform: 'rotate(45deg)' }} />,
    },
  ]

  const [showForm, setShowForm] = useState(true)

  if (status === 'failed') {
    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" pt={7} textAlign="center">
              Something Went Wrong{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography>
              Sorry, something went wrong. Please refresh the page and try
              again.
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button size="small" onClick={() => window.location.reload()}>
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Container>
    )
  } else if (showForm && status === 'unclaimed') {
    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center" spacing={2} pt={4} pb={10}>
          <Grid item xs={11}>
            <Box display="flex" alignItems="center" width="100%" pr="25px">
              <img
                src={logo}
                alt="Claimtag Logo"
                style={{ width: '20px', marginRight: '7px' }}
              />
              <Typography variant="h6">Claimtags</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4">Claim Your Badge</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h6">
              Enter a link you'd like to share the next time someone scans your
              badge.
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Form status={status} setStatus={setStatus} />
          </Grid>
        </Grid>
        {/* <Grid item xs={11}>
          <div
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 'auto',
              textAlign: 'center',
              borderTop: '1px solid #e0e0e0',
              paddingTop: '5px',
              paddingBottom: '10px',
              paddingRight: '5px',
              paddingLeft: '5px',
              position: 'fixed',
              zIndex: 2000,
              backgroundColor: 'white',
              fontSize: '12px',
            }}
          >
            <Typography color="inherit" variant="body2" textAlign="center">
              Create Claimtags for your next event at{' '}
            </Typography>
            <Typography color="inherit" variant="body2" textAlign="center">
              <Link href="https://claimtag.io" target="_blank">
                <b>www.claimtag.io</b>
              </Link>
            </Typography>
          </div>
        </Grid> */}
      </Container>
    )
  } else if (!showForm && status === 'unclaimed') {
    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center" spacing={3} pt={4} pb={10}>
          <Grid item xs={11}>
            <Box display="flex" alignItems="center" width="100%" pr="25px">
              <img
                src={logo}
                alt="Claimtag Logo"
                style={{ width: '20px', marginRight: '7px' }}
              />
              <Typography variant="h6">Claimtags</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4" textAlign="center">
              Claim Your Badge
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Card variant="outlined">
              <CardContent sx={{ pt: 5 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box textAlign="center" width="100%">
                      {pages[page].Icon}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography mb={1} variant="h6" textAlign="center">
                      {pages[page].text}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <PaginationDots
                      index={page}
                      count={pages.length}
                      color="black"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={11}>
            <Box display="flex">
              {page > 0 && (
                <Box pr={1}>
                  <Button
                    // startIcon={<ArrowBackIos />}
                    variant="outlined"
                    size="large"
                    onClick={() => setPage(page - 1)}
                  >
                    <b>Back</b>
                  </Button>
                </Box>
              )}
              {page < pages.length - 1 ? (
                <Box flexGrow={1}>
                  <Button
                    fullWidth
                    endIcon={<ArrowForwardIos />}
                    variant="contained"
                    size="large"
                    onClick={() => setPage(page + 1)}
                  >
                    <b>Next</b>
                  </Button>
                </Box>
              ) : (
                <Box flexGrow={1}>
                  <Button
                    fullWidth
                    endIcon={<ArrowForwardIos />}
                    variant="contained"
                    size="large"
                    onClick={() => setShowForm(true)}
                  >
                    <b>Get Started</b>
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        {/* <Grid item xs={11}>
            <div
              style={{
                bottom: 0,
                left: 0,
                right: 0,
                top: 'auto',
                textAlign: 'center',
                borderTop: '1px solid #e0e0e0',
                paddingTop: '5px',
                paddingBottom: '10px',
                paddingRight: '5px',
                paddingLeft: '5px',
                position: 'fixed',
                zIndex: 2000,
                backgroundColor: 'white',
                fontSize: '12px',
              }}
            >
              <Typography color="inherit" variant="body2" textAlign="center">
                Create Claimtags for your next event at{' '}
              </Typography>
              <Typography color="inherit" variant="body2" textAlign="center">
                <Link href="https://claimtag.io" target="_blank">
                  <b>www.claimtag.io</b>
                </Link>
              </Typography>
            </div>
          </Grid> */}
      </Container>
    )
  } else if (status === 'success') {
    return (
      <Container maxWidth="xs">
        <Grid container justifyContent="center" spacing={3} pt={4} pb={10}>
          <Grid item xs={11}>
            <Box display="flex" alignItems="center" width="100%" pr="25px">
              <img
                src={logo}
                alt="Claimtag Logo"
                style={{ width: '20px', marginRight: '7px' }}
              />
              <Typography variant="h6">Claimtags</Typography>
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4" textAlign="center">
              Success!
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h5" textAlign="center">
              You claimed your badge
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h6" textAlign="center">
              Remember: You can re-scan your own badge to see a list of the
              people you've connected with, or update your link.
            </Typography>
          </Grid>
          <Grid item xs={11} textAlign="center">
            <Button
              component={RouterLink}
              to="/me"
              variant="contained"
              fullWidth
              endIcon={<ThumbUp />}
              size="large"
            >
              <b>Got it</b>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <div
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 'auto',
              textAlign: 'center',
              borderTop: '1px solid #e0e0e0',
              paddingTop: '5px',
              paddingBottom: '10px',
              paddingRight: '5px',
              paddingLeft: '5px',
              position: 'fixed',
              zIndex: 2000,
              backgroundColor: 'white',
              fontSize: '12px',
            }}
          >
            <Typography color="inherit" variant="body2" textAlign="center">
              Create Claimtags for your next event at{' '}
            </Typography>
            <Typography color="inherit" variant="body2" textAlign="center">
              <Link href="https://claimtag.io" target="_blank">
                <b>www.claimtag.io</b>
              </Link>
            </Typography>
          </div>
        </Grid>
      </Container>
    )
  } else {
    return <Loading />
  }
}

export default ClaimtagForm
