import React from 'react'
import { Box, Grid, ButtonBase } from '@mui/material'

const PaginationDots = ({ count, index, setIndex, color }) => {
  const dots = [...Array(count)]

  const handleClick = i => {
    !!setIndex && setIndex(i)
  }

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <Box display="flex">
          {count > 1 &&
            dots.map((dot, i) => {
              return (
                <ButtonBase key={i} onClick={() => handleClick(i)}>
                  <Box
                    bgcolor={color}
                    borderRadius="50%"
                    width="7px"
                    height="7px"
                    mr="5px"
                    style={i !== index ? { opacity: '30%' } : null}
                  />
                </ButtonBase>
              )
            })}
        </Box>
      </Grid>
    </Grid>
  )
}

export default PaginationDots
