import React, { useEffect, useState } from 'react'
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import usePageTrack from 'hooks/use-page-track'

import { request } from 'util/client'
import { CheckOutlined, Edit, OpenInNew } from '@mui/icons-material'

const FieldEditLink = ({ url, setClaimtag }) => {
  const [status, setStatus] = useState('idle')

  usePageTrack()

  const [isSelected, setIsSelected] = useState(false)
  const [value, setValue] = useState(url)

  const handleBlur = async () => {
    let tempUserId
    setIsSelected(false)

    try {
      tempUserId = localStorage.getItem('tempUserId')
    } catch (err) {
      setStatus('failed')
    }

    if (!!tempUserId) {
      if (value !== url) {
        setStatus('pending')
        try {
          let res = await request({
            url: `/claims`,
            data: { id: tempUserId, url: value },
            method: 'PATCH',
          })
          console.log(res)

          setClaimtag(res.claimtag)
          const timer = setTimeout(() => {
            setStatus('succeeded')
          }, 1000)

          return () => clearTimeout(timer)
        } catch (err) {
          setStatus('failed')
        }
      }
    } else {
      setStatus('failed')
    }
  }

  useEffect(() => {
    if (status === 'succeeded') {
      const timer = setTimeout(() => {
        setStatus('idle')
      }, 2000)

      return () => clearTimeout(timer)
    }
  })

  console.log(status)

  return (
    <Box display="flex" maxWidth="100%" alignItems="center">
      <Box flexGrow={1} flexBasis={0} minWidth={0}>
        {/* <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    borderRadius: 50,
                    textTransform: 'none',
                  }}
                  endIcon={<OpenInNew />}
                > */}
        {/* {user.claimtag.url.replace('https://', '')} */}
        {/* <Card
                  variant="outlined"
                  elevation={0}
                  sx={{ borderRadius: 50 }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Typography textOverflow={'ellipsis'} overflow="hidden">
                        testtesttesttesttestthisiswhatitlookslikewithalongurl
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card> */}
        <TextField
          fullWidth
          variant="outlined"
          value={value}
          onChange={e => setValue(e.target.value)}
          disabled={status === 'loading'}
          InputProps={{
            endAdornment: isSelected ? null : (
              <InputAdornment position="end">
                <Edit />
              </InputAdornment>
            ),
          }}
          sx={{
            '& label': { paddingLeft: theme => theme.spacing(2) },
            '& input': {
              paddingLeft: theme => theme.spacing(3),
              paddingRight: isSelected ? theme => theme.spacing(3) : null,
            },
            '& fieldset': {
              paddingLeft: theme => theme.spacing(2.5),
              borderRadius: '30px',
            },
          }}
          onFocus={e => setIsSelected(true)}
          onBlur={handleBlur}
        />
        {/* </Button> */}
      </Box>
      <Box>
        {status === 'pending' ? (
          <IconButton edge="end">
            <CircularProgress size="25.7px" />
          </IconButton>
        ) : isSelected ? (
          <IconButton edge="end" color="primary">
            <CheckOutlined />
          </IconButton>
        ) : status === 'succeeded' ? (
          <IconButton edge="end">
            <CheckOutlined />
          </IconButton>
        ) : (
          <IconButton edge="end" href={url} target="_blank">
            <OpenInNew />
          </IconButton>
        )}
      </Box>
    </Box>
  )
}

export default FieldEditLink
